// import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// 销售退货列表
export function getSalesReturnList(data) {
  return dmshttp({
    url: 'getSalesReturnList',
    data
  })
}
// 销售退货-退货-查询详情
export function orderReturnDetail(data) {
  return dmshttp({
    url: 'orderReturnDetail',
    data
  })
}
// 销售退货-退货入库-入库-查询详情接口
export function agentReturnOrderDetail(data) {
  return dmshttp({
    url: 'agentReturnOrderDetail',
    data
  })
}

// 销售退货-退货-退货提交接口
export function createReturnOrderInbound(data) {
  return dmshttp({
    url: 'createReturnOrderInbound',
    data
  })
}
// 销售退货-退货入库-入库-入库提交接口
export function agentReturnOrderInbound(data) {
  return dmshttp({
    url: 'agentReturnOrderInbound',
    data
  })
}
// 正品退货列表
export function agentReturnOrderListNew(data) {
  return dmshttp({
    url: 'agentReturnOrderListNew',
    data
  })
}









export function batch(data) {
  return dmshttp({
    url: 'batchInvalidSalesOrder',
    data
  })
}


// 驳回
export function orderReject(data) {
  return dmshttp({
    url: 'rejectPurchaseOrder',
    data
  })
}



//取消审核(
export function cancleReject(data) {
  return dmshttp({
    url: 'deleteReserverBySoId',
    data
  })
}

// 导出

export function exportOrder(data) {
  return dmshttp({
    url: 'exportSalesReturnList' + '&type=1',
    data,
    responseType: 'blob'
  })
}


// 分銷商按单退货
export function retrunOrderQuest(data) {
  return dmshttp({
    url: 'purchaseCreationReturn',
    data
  })
}

export function salesReturn(data) {
  return dmshttp({
    url: 'cancelReturn',
    data
  })
}
// 销售出库单明细查询
export function getOrderOutList(data) {
  return dmshttp({
    url: "agentReturnOrderDetail",
    data
  });
}



// 获取出库表格信息
export function getTableList(data) {
  return dmshttp({
    url: 'getWarehouseList',
    data
  })
}

// 点击入库
export function saleOrderOut(data) {
  return dmshttp({
    url: 'agentReturnOrderInbound',
    data
  })
}
// 判断是否启用补差类型
export function difference(data) {
  return dmshttp({
    url: 'isEnableOrNot',
    data
  })
}