<template>
 <div>
   <a-modal
     v-model="visible"
     @cancel="cancle"
     wrapClassName="cancelName"
     :width="1188"
     :closable="false"
     centered
     :footer="false"
   >
     <div class="modal_close" @click="goback">
       <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
     </div>
     <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>退机申请
      </span>
     </div>
     <div class="applyListBox">
       <div class="applyItem"
            v-for="(item,index) in applyList"
            :key="index"
            @mouseenter="mousemove(item)"
            @mouseleave="leave(item)"
       >
         <div class="textBox">
           <p>产品型号：{{item.modelName}}</p>
           <p>物料编码：{{item.materialCode}}</p>
           <p>仓库：{{item.gicWarehouseName}}</p>
           <p>机编码：{{item.machineCode}}</p>
           <p v-if="identificatioSheetNo == 0">CRM鉴定单号：{{item.identificationSheeNo}}</p>
           <p v-if="identificatioSheetNo == 0">鉴定日期：{{item.identificationShee}}</p>
           <p >返机类型：{{item.reverseTypeName}}</p>
           <p>备注：{{item.remark}}</p>
           <p>特殊标记：{{item.specialMarkings}}</p>
           <p>重复发起原因：{{item.repeatedReasons}}</p>
         </div>
         <div class="opration">
           <p class="edit" v-if="item.display" @click="openAddEdit(index,item,'edit')">
             <img style="width: 16px;height: 16px" src="@/assets/IntentionList/编辑@2x.png" />
             编辑</p>
           <p class="del" v-if="item.display" @click="showConfirm(index)">
             <img style="width: 16px;height: 16px" src="@/assets/IntentionList/删除@2x.png" />
             删除</p>
         </div>
       </div>
        <div class="bigBox">
          <div class="addItem">
            <div class="everyItem">
              <div class=" labelone">
                <span>*</span><p>产品型号：</p>
              </div>
              <a-input
                ref="selectVal"
                class="selectAnt"
                v-model="addForm.modelName"
                @focus="openProuct"
                disabled
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div class="labelWidth">
                <p>物料编码：</p>
              </div>
              <a-input
                disabled
                class="selectAnt"
                v-model="addForm.materialCode"
                placeholder="物料编码"
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div class="label">
                <p>仓库：</p>
              </div>
              <a-input
                disabled
                class="selectAnt"
                v-model="addForm.gicWarehouseName"
                placeholder=""
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div class="labelone">
                <span>*</span><p>机编码：</p>
              </div>
              <a-input
                v-if="identificatioSheetNo == 1 "
                @blur="changeMachineCode"
                @focus="machineCodeFocus"
                class="selectAnt"
                v-model="addForm.machineCode"
                placeholder="请输入"
                maxLength="23"
                :disabled="addForm.inputDisabled"
              >
              </a-input>
              <a-select
                v-if="(addForm.disabled || machineCoderList.length >= 0) && identificatioSheetNo == 0"
                :disabled="addForm.disabled"
                placeholder="请选择"
                class="selectAnt"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChangemachineCodeList"
                v-model="addForm.machineCode"
                dropdownMatchSelectWidth
              >
                <a-select-option
                  :value="item"
                  v-for="item in machineCoderList"
                  :key="item"
                >{{ item }}
                </a-select-option
                >
              </a-select>
            </div>
            <div class="everyItem" v-if="identificatioSheetNo == 0">
              <div class=" labelWidth">
                <span>*</span><p>CRM鉴定单号：</p>
              </div>
              <a-input
                :disabled="addForm.jdDisabled"
                class="selectAnt"
                v-model="addForm.identificationSheeNo"
                option-filter-prop="children"
                placeholder="请输入"
                @blur="changeSheeNo"
              >
              </a-input>
            </div>
            <div class="everyItem" v-if="identificatioSheetNo == 0">
              <div class="label">
                <p>鉴定日期：</p>
              </div>
              <a-input
                disabled="item.disabled"
                class="selectAnt"
                v-model="addForm.identificationShee"
                option-filter-prop="children"
                placeholder="鉴定日期"
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div :class="this.identificatioSheetNo==0 ? 'label' : 'labelWidth'">
                <span>*</span><p>返机类型：</p>
              </div>
              <a-select
                placeholder="请选择"
                class="selectAnt"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChangeReverseTypeList"
                v-model="addForm.reverseTypeName"
                dropdownMatchSelectWidth
              >
                <a-select-option
                  :value="item.name"
                  v-for="item in reverseTypetrList"
                  :key="item.code"
                >{{ item.name }}
                </a-select-option
                >
              </a-select>
            </div>
            <div class="everyItem">
              <div :class="this.identificatioSheetNo==0 ? 'labelWidth' : 'label'">
                <p>备注：</p>
              </div>
              <a-input
                class="selectAnt"
                v-model="addForm.remark"
                option-filter-prop="children"
                placeholder="请输入"
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div :class="this.identificatioSheetNo==0 ? 'label' : 'label'">
                <p>特殊标记：</p>
              </div>
              <a-input
                disabled
                class="selectAnt"
                v-model="addForm.specialMarkings"
                option-filter-prop="children"
                placeholder="自动带出"
              >
              </a-input>
            </div>
            <div class="everyItem">
              <div class="labelWidth">
                <span v-if="addForm.specialMarkings">*</span><p>重复发起原因：</p>
              </div>
              <a-input
                class="selectAnt"
                v-model="addForm.repeatedReasons"
                option-filter-prop="children"
                placeholder="请输入"
              >
              </a-input>
            </div>
          </div>
          <div class="btnBox">
            <h-btn
              class="btn"
              :width="92"
              :height="32"
              content="重置"
              fontSize="16"
              @click="resetProInfo('reset')"
              borderRadius="4"
              style="background: #FFFFFF;box-shadow:none;color:#777;text-shadow:none;border: 1px solid #ccc;margin-right: 16px"
            />
            <h-btn
              class="btn"
              :width="92"
              :height="32"
              content="确定"
              @click="addPro"
              fontSize="16"
              borderRadius="4"
              style="background: #00AAA6;box-shadow:none;text-shadow:none;color:#fff"
            />
          </div>
        </div>
     </div>
     <div class="bottombtn">
       <h-btn
         class="btn"
         :width="188"
         :height="48"
         content="返回"
         type="primary"
         @click="goback"
         fontSize="16"
         borderRadius="4"
         style="background: #aaa;color:#fff;border: none"
        />
     </div>
   </a-modal>
   <delete-modal :visible.sync="isLoginModalShow" info="确认删除？" @deleteHandOk="userLayout"/>
   <product-modal
     type="sale"
     v-model="showProuct"
     :orgId="orgId"
     :warehouseId="warehouseId"
     :rowSelectionType="rowSelectionType"
     @submit="addProduct"
     :materialGroupCode ="materialGroupCode"
     :productName="productName"
   />
 </div>
</template>

<script>

import ProductModal from "../components/ProductModal.vue";
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { verifyCode } from "@/views/defectiveRetirementNew/hisenseRrturnInput/components/api";
export default {
  name: "addUserSource-modal",
  components: {
    ProductModal
  },
  data() {
    return {
      fetching: false,
      submitBtn: true,
      applyList: [],
      rowSelectionType: 'checkbox',
      addForm:{ // 新增数据
        modelName: undefined, // 产品型号
        materialCode: '', // 物料编码
        machineCode: '', // 机编码
        identificationSheeNo: '', // CRM鉴定单号
        identificationShee: '', // 鉴定日期
        reverseTypeId: undefined, // 返机类型id
        reverseTypeName: undefined, // 返机类型name
        remark: '', // 备注
        colour:'',
        volume:'',
        invStatusTypeName:this.invStatusTypeName,
        invStatusType:this.invStatusType,
        invStatusId:this.invStatusId,
        invStatusName:this.invStatusName,
        brandName:'',
        productId:'',
        orgCode:this.orgCodeF,
        bigQty:'',
        materialGroupCode:this.materialGroupCode,
        disabled: true, //机编码 编辑控制
        jdDisabled: false,  // CRM鉴定单号能不能编辑控制
        inputDisabled:false, // INPUT框 及编码是否可编辑
        gicWarehouseName:'', //仓库名称
        gicWarehouse:'', //仓库id
        gicOwner:'',
        gicPriceprottype:this.invStatusTypeName,
        repeatedReasons:'',//重复发起原因
        specialMarkings:'',//特殊标记
        duplicateRecord:'',
        oldMaterialCode:''
      },
      reverseTypetrList:[], // 返机类型数据
      machineCoderList:[], // 机编码数组
      showProuct:false,
      optionType: '', // 操作类型 edit-编辑 add-新增
      editIndex: '', // 编辑的下标
      showApply: true, // 展示新增数据那一开
      isLoginModalShow:false, // 删除确定框
      canAdd:true,
      warningInfo:''
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orgId:{
      type: String,
      default: function () {
        return "";
      }
    },
    receiveID:{
      type: String,
      default: function () {
        return "";
      }
    },
    warehouseId:{
      type: String,
      default: function () {
        return "";
      }
    },
    materialGroupCode: {
      type: String,
      default: function () {
        return "";
      }
    },
    identificatioSheetNo: {
      type: Number,
      default: function () {
        return 0;
      }
    },
    productName: {
      type: String,
      default: function () {
        return "";
      }
    },
    materialCode: {
      type: String,
      default: function () {
        return "";
      }
    },
    gicOutWarehouseName: {
      type: String,
      default: function () {
        return "";
      }
    },
    gicOutWarehouse: {
      type: String,
      default: function () {
        return "";
      }
    },
    invStatusTypeName: {
      type: String,
      default: function () {
        return "";
      }
    },
    invStatusType: {
      type: String,
      default: function () {
        return "";
      }
    },
    invStatusId: {
      type: String,
      default: function () {
        return "";
      }
    },
    invStatusName: {
      type: String,
      default: function () {
        return "";
      }
    },
    orgCodeF: {
      type: String,
      default: function () {
        return "";
      }
    }
  },
  watch:{
    visible: {
      immediate: true,
      handler(newValue) {
        this.machineCoderList = []
        this.optionType = 'add'
        this.addForm = {
          modelName:this.productName, // 产品型号
          materialCode:this.materialCode, // 物料编码
          machineCode:'', // 机编码
          identificationSheeNo:'', // CRM鉴定单号
          identificationShee:'', // 鉴定日期
          reverseTypeId:undefined, // 返机类型id
          reverseTypeName:undefined, // 返机类型name
          remark:'', // 备注
          colour:'',
          volume:'',
          invStatusTypeName:this.invStatusTypeName,
          invStatusType:this.invStatusType,
          invStatusId:this.invStatusId,
          invStatusName:this.invStatusName,
          brandName:'',
          productId:'',
          bigQty:'',
          materialGroupCode:this.materialGroupCode,
          orgCode:this.orgCodeF,
          disabled: true, //机编码 编辑控制
          jdDisabled: false,  // CRM鉴定单号能不能编辑控制
          display : false,
          inputDisabled:false,
          gicWarehouseName:this.gicOutWarehouseName,
          gicWarehouse:this.gicOutWarehouse,
          repeatedReasons:'',//重复发起原因
          specialMarkings:'',//特殊标记
          duplicateRecord:'',
          oldMaterialCode:''
        }
      }
    },
    addForm: {
      immediate: true,
      handler(newValue) {
        if(newValue.modelName && newValue.materialCode){
          this.addForm.inputDisabled = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getReverseTypetrList()
  },
  methods: {
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.display = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.display = false;
      this.$forceUpdate();
    },
    // 修改CRM鉴定单号
    changeSheeNo(e){
      //  调用crm接口 获取机编码 鉴定日期
      //   如果接口返回数据机编码的disabled 为true 否则为false
      if (e.target.value.trim().length > 0 ) {
        let data = {
          identificationCode:e.target.value,
          machineCode:'',
          organizationCode: this.receiveID,// 组织编码
          productModel: this.addForm.modelName,
          identificationFlag:this.identificatioSheetNo,
          matnrCode: this.addForm.materialCode
        }
        verifyCode(data).then(res=>{
          if( res.data.code == 0 ){
            // 查询接口中的机编码  如果机编码只有一个 就默认 而且下拉框不能操作  如果有多个 默认第一个 可以编辑
            this.machineCoderList = res.data.data.machineCodeList?res.data.data.machineCodeList:[]
            this.addForm.identificationShee = res.data.data.finishTime
            //TODO: 特殊标记通过接口查出
            this.addForm.specialMarkings= res.data.data.manyTimesReturn == 'true' ? '多次提交':''
            this.addForm.duplicateRecord= res.data.data.duplicateRecord?res.data.data.duplicateRecord:''

            // 返机类型
            let obj = this.reverseTypetrList.find((i) => {
              return i.code == res.data.data.returnTypeId; //筛选出匹配数据
            });
            this.addForm.reverseTypeName = obj && obj.name ? obj.name : ''
            this.addForm.reverseTypeId = obj && obj.name ? res.data.data.returnTypeId:''

            if(this.machineCoderList .length <= 0 ) {
              this.machineCoderList = []
              this.addForm.identificationShee = ''
              this.addForm.machineCode = ''
              this.addForm.disabled = true
              return;
            }
            if(this.machineCoderList.length == 1) {
              this.addForm.machineCode = this.machineCoderList[0]
              if(this.addForm.machineCode && this.addForm.materialCode){
                if(this.addForm.materialCode !==  this.addForm.machineCode.slice(0,12)){
                  this.addForm.oldMaterialCode = this.addForm.materialCode
                  this.addForm.materialCode = this.addForm.machineCode.slice(0,12)
                }
              }
              this.addForm.disabled = true
              return;
            }
            if(this.machineCoderList.length > 1 ) {
              this.addForm.machineCode = this.machineCoderList[0]
              this.addForm.disabled = false
              return;
            }
          } else {
            this.$message.warning(res.data.msg)
            this.machineCoderList = []
            this.addForm.reverseTypeId = '' // 返机类型id
            this.addForm.reverseTypeName = '' // 返机类型name
            this.addForm.identificationShee = ''
            this.addForm.identificationSheeNo = ''
            this.addForm.machineCode = ''
            this.addForm.disabled = true
          }
        }).catch(error=>{
          console.log(error);
          this.machineCoderList = []
          this.addForm.reverseTypeId = '' // 返机类型id
          this.addForm.reverseTypeName = '' // 返机类型name
          this.addForm.identificationShee = ''
          this.addForm.identificationSheeNo = ''
          this.addForm.machineCode = ''
        })
      }

    },
    // 机编码获取焦点触发事件
    machineCodeFocus() {
      if (!this.addForm.materialCode) {
        this.$message.warning('请先选择产品')
        this.addForm.inputDisabled = true
        this.$forceUpdate();
        return
      }
    },
    // 输入框及编码修改
    changeMachineCode(e){
      if(!e.target.value ) {
        this.$message.warning('请输入机编码')
        return
      }
      if(e.target.value.trim().length < 23 ) {
        this.$message.warning('请输入23位机编码')
        return
      }
      if(e.target.value.trim().length > 23 ) {
        this.$message.warning('最多只能输入23位机编码')
        return
      }
      // if(this.identificatioSheetNo == 1&& e.target.value.trim().length > 0){
      //   let cherkNUm = 0
      //   if(this.addForm.materialCode){
      //     cherkNUm = this.addForm.materialCode.trim().length
      //   }
      //   if(e.target.value.trim().slice(0, cherkNUm) !== this.addForm.materialCode) {
      //     this.$message.warning('机编码前12位必须与物料编码前12位一致')
      //     this.addForm.machineCode  = ''
      //     return
      //   }
      // }
      this.addForm.machineCode = e.target.value
      if(this.identificatioSheetNo == 1 && e.target.value.trim().length > 0 &&  this.addForm.machineCode.trim().length == 23 ) {
        let data = {
          machineCode: this.addForm.machineCode,
          identificationCode:'',
          organizationCode: this.receiveID,// 组织编码
          productModel: this.addForm.modelName,
          identificationFlag:this.identificatioSheetNo,
          matnrCode: this.addForm.materialCode
        }
        verifyCode(data).then( res => {
          if( res.data.code == 0 ){
            //TODO: 特殊标记通过接口查出
            this.addForm.specialMarkings= res.data.data.manyTimesReturn == 'true' ? '多次提交':''
            this.addForm.duplicateRecord= res.data.data.duplicateRecord?res.data.data.duplicateRecord:''

            // 返机类型
            if(res.data.data.returnTypeId) {
              let obj = this.reverseTypetrList.find((i) => {
                return i.code == res.data.data.returnTypeId; //筛选出匹配数据
              });
              this.addForm.reverseTypeName = obj && obj.name ? obj.name : ''
              this.addForm.reverseTypeId = obj && obj.name ? res.data.data.returnTypeId:''
            }
          } else {
            this.$message.warning(res.data.msg)
            this.addForm.machineCode = ''
            this.addForm.identificationShee = ''
            return
          }
        }).catch(error=>{
          console.log(error);
        })
      }
    },
    // 修改机编码
    handleChangemachineCodeList(e){
      this.addForm.machineCode = e
      if(this.addForm.machineCode && this.addForm.materialCode){
        if(this.addForm.materialCode !==  this.addForm.machineCode.slice(0,12)){
          this.addForm.oldMaterialCode = this.addForm.materialCode
          this.addForm.materialCode = this.addForm.machineCode.slice(0,12)
        }
      }
    },
    // 获取返机类型 ReverseTypetrList
    getReverseTypetrList(){
      let data = {
        pid: 15029395643
      };
      focusonremarkplz(data).then(res => {
        this.reverseTypetrList = res.data.list;
      });
    },
    // 增加退货信息
   async addPro(){
        if(this.identificatioSheetNo == 1 && !this.addForm.machineCode ) {
          this.$message.warning('请输入机编码')
          return
        }
        if(this.identificatioSheetNo == 1 && this.addForm.machineCode.trim().length < 23 ) {
          this.$message.warning('请输入23位机编码')
          return
        }
     if(this.identificatioSheetNo == 0 && !this.addForm.identificationSheeNo ) {
       this.$message.warning('请输入鉴定单号')
       return
     }
     if(this.addForm.specialMarkings && !this.addForm.repeatedReasons ){
       this.$message.warning('请输入重复发起原因')
       return
     }
        // 校验机编码前12-14位的物料编码和所选产品的物料编码是否一致
       if(this.addForm.materialCode && this.addForm.materialCode.trim().length > 0){
          let data = {
            machineCode: this.addForm.machineCode,
            identificationCode:'',
            organizationCode: this.receiveID,// 组织编码
            productModel: this.addForm.modelName,
            identificationFlag:this.identificatioSheetNo,
            matnrCode: this.addForm.materialCode
          }
         await verifyCode(data).then( res => {
            if( res.data.code == 0 ){
              //TODO: 特殊标记通过接口查出
              this.addForm.specialMarkings= res.data.data.manyTimesReturn == 'true' ? '多次提交':''
              this.addForm.duplicateRecord= res.data.data.duplicateRecord?res.data.data.duplicateRecord:''

              // 返机类型
              if(res.data.data.returnTypeId) {
                let obj = this.reverseTypetrList.find((i) => {
                  return i.code == res.data.data.returnTypeId; //筛选出匹配数据
                });
                this.addForm.reverseTypeName = obj && obj.name ? obj.name : ''
                this.addForm.reverseTypeId = obj && obj.name ? res.data.data.returnTypeId:''
              }
              this.canAdd = true
            } else {
              // this.$message.warning(res.data.msg)
              this.addForm.machineCode = ''
              this.addForm.identificationShee = ''
              this.canAdd = false
              this.warningInfo = res.data.msg
              return
            }
          }).catch(error=>{
            console.log(error);
          })
        }
       if(!this.canAdd) {
         this.$message.warning(this.warningInfo)
         return
       }
      // 如果是编辑的话 进行如下操作
      if(this.optionType == 'edit') {
        if(this.identificatioSheetNo == 0  && this.addForm.identificationSheeNo.trim().length == 0  ) {
          this.$message.warning('CRM鉴定单号不能为空，请填写！')
          return
        }
        this.applyList[this.editIndex] = JSON.parse(JSON.stringify(this.addForm))
        this.$forceUpdate()
        this.addForm = {
          modelName: this.applyList.length > 0 ? this.applyList[this.applyList.length-1].modelName : undefined, // 产品型号
          materialCode: this.applyList.length > 0 ? this.applyList[this.applyList.length-1].materialCode : "", // 物料编码
          machineCode: '', // 机编码
          identificationSheeNo: '', // CRM鉴定单号
          identificationShee: '', // 鉴定日期
          reverseTypeId: undefined, // 返机类型id
          reverseTypeName: undefined, // 返机类型name
          remark: '', // 备注
          colour:'',
          volume:'',
          invStatusId:'',
          invStatusName:'',
          brandName:'',
          productId:'',
          bigQty:'',
          orgCode:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].orgCode : '',
          materialGroupCode:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].materialGroupCode : '',
          disabled: true, //机编码 编辑控制
          jdDisabled: false,  // CRM鉴定单号能不能编辑控制
          display : false,
          inputDisabled:false,
          gicWarehouseName:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].gicWarehouseName :'',
          gicWarehouse:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].gicWarehouse :'', //仓库id
          ownerNumber:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].ownerNumber :'',
          invStatusTypeName:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].invStatusTypeName :'',
          repeatedReasons:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].repeatedReasons :'',//重复发起原因
          specialMarkings:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].specialMarkings :'',//特殊标记
          duplicateRecord:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].duplicateRecord :'',
          gicInInvDate:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].gicInInvDate :'',//
        }
        this.optionType = 'add'
        return;
      }
      // 如果是新增 进行一下操作
      if(this.identificatioSheetNo == 1 && !this.addForm.machineCode ) {
        this.$message.warning('请输入机编码')
        return
      }
      if(this.identificatioSheetNo == 1 && this.addForm.machineCode.trim().length < 23 ) {
        this.$message.warning('请输入23位机编码')
        return
      }

      if(!this.addForm.modelName){
        this.$message.warning('产品名称不能为空，请选择！')
        return
      }
      // 如果是有鉴定单的 弹出提示
      if(this.identificatioSheetNo == 0 && !this.addForm.identificationSheeNo){
        this.$message.warning('CRM鉴定单号不能为空，请填写！')
        return
      }
      //  弹出提示
      if( !this.addForm.reverseTypeId){
        this.$message.warning('返机类型不能为空，请选择！')
        return
      }

      if(!this.addForm.machineCode){
        this.$message.warning('机编码不能为空！')
        return
      }


      let num = 0
      if(this.applyList.length > 0 ) {
        this.applyList.forEach(item => {
          if(this.addForm.machineCode == item.machineCode){
            num += 1
          }
        })
      }
      if( num > 0 ) {
        this.$message.warning('已经有相同的机编码，请重新填写')
        return
      }
      // this.applyList.push(this.addForm)
      // this.addForm = {
      //   modelName: this.applyList.length > 0 ? this.applyList[this.applyList.length-1].modelName : undefined, // 产品型号
      //   materialCode: this.applyList.length > 0 ? this.applyList[this.applyList.length-1].materialCode : "", // 物料编码
      //   machineCode: '', // 机编码
      //   identificationSheeNo: '', // CRM鉴定单号
      //   identificationShee: '', // 鉴定日期
      //   reverseTypeId: undefined, // 返机类型id
      //   reverseTypeName: undefined, // 返机类型name
      //   remark: '', // 备注
      //   colour:'',
      //   volume:'',
      //   invStatusId:'',
      //   invStatusName:'',
      //   brandName:'',
      //   productId:'',
      //   orgCode:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].orgCode : '',
      //   bigQty:'',
      //   materialGroupCode:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].materialGroupCode : '',
      //   disabled: true, //机编码 编辑控制
      //   jdDisabled: false,  // CRM鉴定单号能不能编辑控制
      //   display : false,
      //   inputDisabled:false,
      //   gicWarehouseName:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].gicWarehouseName :'',
      //   gicWarehouse:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].gicWarehouse :'', //仓库id
      //   ownerNumber:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].ownerNumber :'',
      //   invStatusTypeName:this.applyList.length > 0 ? this.applyList[this.applyList.length-1].invStatusTypeName :'',
      // }
     this.$emit('update:visible',false)
     this.$emit("confirmOk", this.addForm);
    },
    goback(){
      this.$emit('update:visible',false)
    },
    resetProInfo(type){
      this.addForm = {
        modelName: undefined, // 产品型号
        materialCode: '', // 物料编码
        machineCode: '', // 机编码
        identificationSheeNo: '', // CRM鉴定单号
        identificationShee: '', // 鉴定日期
        reverseTypeId: undefined, // 返机类型id
        reverseTypeName: undefined, // 返机类型name
        remark: '', // 备注
        colour:'',
        volume:'',
        invStatusId:'',
        invStatusName:'',
        brandName:'',
        productId:'',
        orgCode:'',
        bigQty:'',
        materialGroupCode:'',
        disabled: true, //机编码 编辑控制
        jdDisabled: false,  // CRM鉴定单号能不能编辑控制
        display : false,
        inputDisabled:false,
        gicWarehouseName:'',
        gicWarehouse:'',

      }
      this.optionType = 'add'
    },
    // 产品弹窗增加产品
    addProduct(productList) {
      // 型号
      this.addForm.modelName = productList[0].zzprdmodel
      this.$set(this.addForm,'modelName',productList[0].zzprdmodel)
      // 物料编码
      this.addForm.materialCode = productList[0].materialCode
      // 品牌
      this.addForm.brandName = productList[0].brandName
      // 颜色
      this.addForm.colour = productList[0].colour
      //  体积
      this.addForm.volume = productList[0].volume
      //  质量等级
      this.addForm.invStatusId = productList[0].invStatusId
      this.addForm.invStatusName = productList[0].invStatusName
      //  可用库存
      this.addForm.bigQty = productList[0].bigQty
      // 产品id
      this.addForm.productId = productList[0].productCode
      // 物料号
      this.addForm.materialGroupCode = productList[0].materialGroupCode
      // 组织code
      this.addForm.orgCode = productList[0].orgCode
      this.addForm.gicWarehouseName = productList[0].gicWarehouseName
      this.addForm.gicInInvDate = productList[0].inInvDate
      this.addForm.gicWarehouse = productList[0].gicWarehouse
      this.addForm.ownerNumber = productList[0].ownerNumber
      this.addForm.invStatusTypeName = productList[0].invStatusTypeName

    },
    // 编辑
    openAddEdit(index,item,type){
      this.optionType = type
      this.editIndex = index;
      this.addForm = JSON.parse(JSON.stringify(item))
    },
    // 确认删除弹窗展示
    showConfirm(index) {
      this.isLoginModalShow = true;
      this.giveindex = index;
    },
    // 确认删除操作
    userLayout() {
      let index = this.giveindex;
      this.applyList.splice(index, 1);
    },
    // 打开产品选择框
    openProuct(){
      this.showProuct = true;
      // 失去焦点
      this.$refs.selectVal.blur();
    },
    // 取消
    cancle(){
      this.$emit('update:visible',false)
      this.applyList = []
      this.optionType = 'add'
      this.addForm = {
        modelName:undefined, // 产品型号
        materialCode:'', // 物料编码
        machineCode:'', // 机编码
        identificationSheeNo:'', // CRM鉴定单号
        identificationShee:'', // 鉴定日期
        reverseTypeId:undefined, // 返机类型id
        reverseTypeName:undefined, // 返机类型name
        remark:'', // 备注
        colour:'',
        volume:'',
        invStatusId:'',
        invStatusName:'',
        brandName:'',
        productId:'',
        bigQty:'',
        materialGroupCode:'',
        disabled: true, //机编码 编辑控制
        jdDisabled: false,  // CRM鉴定单号能不能编辑控制
        display : false,
        inputDisabled:false,
        gicWarehouseName:'',
        gicWarehouse:'',
        ownerNumber:'',
        invStatusTypeName:'',
        oldMaterialCode:''

      }

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 修改返机类型
    handleChangeReverseTypeList(value, e){
      this.addForm.reverseTypeName = value
      this.addForm.reverseTypeId = e.key
    },
    // 点击最后的确定
    confirm() {
      const groupByData = {}
      this.applyList.forEach(item => {
        if (!groupByData[item.modelName]) {
          groupByData[item.modelName] = {
            list: [], // 总量数据
            count: 0, // 数量
            modelName: item.modelName, // 产品型号
            materialCode: item.materialCode, // 物料编码
            orgCode: item.orgCode,
            colour: item.colour, // 颜色
            volume: item.volume, // 体积
            invStatusId: item.invStatusId, // 质量等级code
            invStatusName: item.invStatusName, // 质量等级名称
            bigQty: item.bigQty, // 可用库存
            productId: item.productId, // 产品id
            materialGroupCode: item.materialGroupCode, // 物料号
            gicWarehouseName:item.gicWarehouseName,
            gicInInvDate:item.gicInInvDate,
            gicWarehouse:item.gicWarehouse,
            brandName:item.brandName,
            ownerNumber:item.ownerNumber,
            invStatusTypeName:item.invStatusTypeName,
          }
        }
        groupByData[item.modelName]['count'] = groupByData[item.modelName]['count'] + 1
        groupByData[item.modelName]['list'].push(item)
      })
      const list = []
      const modelNames = Object.keys(groupByData)
      modelNames.forEach(key => {
        list.push(groupByData[key])
      })
      this.$emit("update:visible", false);
      this.$emit("cancelReasonHandOk", list);
      this.applyList = []
      this.addForm = {
        modelName:undefined, // 产品型号
        materialCode:'', // 物料编码
        machineCode:'', // 机编码
        identificationSheeNo:'', // CRM鉴定单号
        identificationShee:'', // 鉴定日期
        reverseTypeId:undefined, // 返机类型id
        reverseTypeName:undefined, // 返机类型name
        remark:'', // 备注
        colour:'',
        volume:'',
        invStatusId:'',
        invStatusName:'',
        brandName:'',
        productId:'',
        bigQty:'',
        materialGroupCode:'',
        disabled: true, //机编码 编辑控制
        jdDisabled: false,  // CRM鉴定单号能不能编辑控制
        display : false,
        inputDisabled:false,
        gicWarehouseName:'',
        gicWarehouse:'',
        ownerNumber:'',
        invStatusTypeName:'',
        oldMaterialCode:''
      }
    },


  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelName {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .applyListBox {
        width: 1140px;
        height: 100%;
        margin-top: 18px;
        .applyItem {
          width: 1140px;
          min-height: 80px;
          background: linear-gradient(263deg, #FBFBFB 0%, #EEF9F9 100%);
          border-radius: 6px;
          margin-bottom: 12px;
          position: relative;
          .opration{
            position: absolute;
            right: 0;
            display: flex;
            color: #1890FF;
            margin-right: 24px;
            top: 16px;
            cursor: pointer;
            .del {
              margin-left: 20px;
            }
          }
          .textBox {
            padding: 19px 300px 3px 24px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            P {
              min-width: 251px;
              font-size: 14px;
              font-weight: 400;
              color: #777777;
              text-align: left;
              margin-left: 10px;
              padding-bottom: 15px;
            }
            p:nth-child(1) {
              font-size: 14px;
              font-weight: 500;
              color: #262626;
            }
            p:nth-child(3n+1) {
              margin-left: 0px;
            }
          }
        }
        .addbutton{
          width: 460px;
          height: 38px;
          border-radius: 6px;
          border: 1px dashed #D9D9D9;
          background-color: #fff;
          margin-top: 4px;
          color: #1890FF;

        }
        .addbutton:hover{
          color: #1890FF;
          border: 1px dashed #1890FF;
          background: rgba(24, 144, 255, 0.08);
        }
        .bigBox {
          width: 1140px;
          height: 100%;
          background: #F2F3F5;
          padding: 24px 16px 0px 16px;
          .addItem {
            border-radius: 6px;
            margin-top: 4px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .everyItem {
              margin-left: 8px;
              display: flex;
              align-items: center;
              margin-bottom: 24px;

              .label{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 78px;
                span {
                  color: #D9161C;
                }
                p{
                  font-size: 14px;
                  color: #262626;
                  font-weight: 400;
                }
              }
              .labelone {
                width: 78px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                span {
                  color: #D9161C;
                }
                p{
                  font-size: 14px;
                  color: #262626;
                  font-weight: 400;
                }
              }
              .labelWidth {
                width: 112px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                span {
                  color: #D9161C;
                }
                p{
                  font-size: 14px;
                  color: #262626;
                  font-weight: 400;
                }
              }
              .marginLeft {
                margin-left: -146px;
              }
              .selectAnt {
                width: 270px!important;
              }
            }
          }
          .btnBox {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-bottom: 24px;
            margin-right: 6px;

          }
        }

      }

      .bottombtn {
        margin-top: 24px;
      }
    }
    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }
}
</style>


