import { submit,purchaseOrderReturnDetail,addImperfectReturnOrder, getCustomerMarketModel } from "./api";
import ProductModal from "../components/ProductModal.vue";
import { getInitInfor, getMatklByOrg, getshAddress } from './api'
import { mapState } from "vuex";
import IdentificationModal from "../components/IdentificationModal.vue";
import IdentificationEditModal from "../components/IdentificationEditModale.vue";
import { queryOrg } from "@/views/activityConfigurationList/edit/api";
import numberBox from './../components/numberBox'
import { createReturnOrderInbound, orderReturnDetail } from "@/views/returnModule/salesReturn/api";
import { getBscList } from "@/views/distributorWarehouse/addDistributorWarehouse/api";
export default {
  name: "",
  components: {
    numberBox,
    ProductModal,
    IdentificationModal,
    IdentificationEditModal
  },

  data() {
    return {
      BussiseGroup: [], // 商家数组
      shopId: '', // 商家id
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入退货信息", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/hisenseReturn/hisenseRrturnInputNew",
          name: "",
          title: "发起残次退"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      pageLoadFlagaa: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "retail",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: 'count'
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      xsmdId: "",
      isEnableOrNot: true,

      //  my
      shopName: '',
      phoneCon: '',
      contacts: '',
      MaterialGroup: [],
      wlz: '',  // 物料组名称
      wlzid: '', // 物料组id
      orgzzId: '', // 销售组织id
      adressName: '', // 地址
      addressId: '', // 地址id
      adressGroup: [], // 地址集合
      transName: '', // 拉货地址transName
      trans: '', // 拉货id
      file: [],
      showTip:false,
      IdentificatioSheetNo:0,// 有无鉴定单号
      showModalIdent:false,// 新增鉴定单弹窗
      editModal:false, // 编辑单条数据弹窗
      editIndex:'',
      proIndex:'',
      editRowInfo: {},
      fileList:[],
      downloadUrlNo: `${process.env.BASE_URL}downLoadTemplate/defectiveReturnTemplate02.xls`,
      downloadUrlHas: `${process.env.BASE_URL}downLoadTemplate/defectiveReturnTemplate01.xls`,
      oldNum:0,
      newNum:0,
      index:'',
      productName:'',
      materialCode:'',
      gicOutWarehouseName:'',
      gicOutWarehouse:'',
      invStatusTypeName:'',
      invStatusType:'',
      invStatusId:'',
      invStatusName:'',
      orgCodeF:'',
      isDisableEditCust: false, // 是否禁止编辑开票户头
      orderNum:'',
      officeId:''
    };
  },
  computed: {
    // productList2: function () {
    //    return JSON.parse(JSON.stringify(this.productList));
    // },
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    }),

  },
  watch:{
    productList2: {
      immediate: true,
      deep: true,
      handler(newValue,old) {
        // if(old&&old.length>0){
        //   this.oldNum= Number(old.filter((it,index)=>index == this.index)[0].bdemandQty)
        //   this.newNum= Number(newValue.filter((it,index)=>index == this.index)[0].bdemandQty)
        //   let numBer = 0
        //   if(this.oldNum>this.newNum){
        //     numBer = this.oldNum-this.newNum
        //     this.productList[this.index].list.splice(0,numBer)
        //   }
        //   if((this.oldNum<this.newNum)){
        //     numBer = this.newNum - this.oldNum
        //     for (let i=0; i<numBer;i++){
        //       this.productList[this.index].list.push({})
        //     }
        //   }
        // }
      }
    },

  },

  mounted() {
    this.getinfor()
    if(this.$route.query.type === 'cg'){
      this.getDetailcg( this.$route.query.id)
    }else{
      this.getDetailXS( this.$route.query.id)
    }
  }


  ,
  methods: {
    async getDetailcg(id) {
      this.pageLoadFlag = true;//
     purchaseOrderReturnDetail({ purchaseOrderId:id ,cisCode: this.$store.state.user.userInfo.customer.cisCode})
        .then(res => {
          this.pageLoadFlag = false
          if (typeof res.data == "string") {
            this.orderDetail = JSON.parse(res.data).data;
          } else {
            this.orderDetail = res.data.data;
          }
          // this.productList = this.orderDetail.returnOrderItemList
          this.orderNum = this.orderDetail.documentNum || ''
          // if(this.orderDetail.billFromId && this.orderDetail.billFromName) {
          //   this.isDisableEditCust=true
          //   this.shopId = this.orderDetail.billFromId
          //   this.shopName = this.orderDetail.billFromName
          // }
            this.orgzzId=this.orderDetail.orgCode
            this.xszhId = this.orderDetail.orgName
            this.receive = this.orderDetail.orgName;
            this.receiveID =this.orderDetail.inOrgCode;
            this.wlzid = this.orderDetail.returnOrderItemList[0].materialGroupCode
            this.wlz = this.orderDetail.returnOrderItemList[0].materialGroupName
          this.getAdress() // 根据销售组织查询退货地址
          this.getMetarialGroup( this.receiveID );
          this.productList = this.orderDetail.returnOrderItemList.map(el => {
            return {
              ...el,
              list : [],
              bdemandQty : 0
            }
          })

          this.$forceUpdate()

        })
        .finally(() => {
          this.pageLoadFlag = false;
        });

    },
    async getDetailXS(id) {
      this.pageLoadFlag = true;//
      orderReturnDetail({  cisCode: this.$store.state.user.userInfo.customer.cisCode,orderId:id})
        .then(res => {
          this.pageLoadFlag = false
          if (typeof res.data == "string") {
            this.orderDetail = JSON.parse(res.data).data;
          } else {
            this.orderDetail = res.data.data;
          }
          // this.productList = this.orderDetail.returnOrderItemList
          this.orderNum = this.orderDetail.documentNum || ''
          // if(this.orderDetail.billFromId && this.orderDetail.billFromName) {
          //   this.isDisableEditCust=true
          //   this.shopId = this.orderDetail.billFromId
          //   this.shopName = this.orderDetail.billFromName
          // }
          this.orgzzId=this.orderDetail.orgId
          this.xszhId = this.orderDetail.orgName
          this.receive = this.orderDetail.orgName;
          this.receiveID =this.orderDetail.inOrgCode;
          this.wlzid = this.orderDetail.itemList[0].materialGroupCode
          this.wlz = this.orderDetail.itemList[0].materialGroupName
          this.getAdress() // 根据销售组织查询退货地址
          this.getMetarialGroup( this.receiveID );
          //获取办事处
          getBscList(this.orgzzId).then((res) => {
            this.officeArr = res.data.list;
            this.officeId = res.data.list.length>0? res.data.list[0].code:''
          });
          this.productList = this.orderDetail.itemList.map(el => {
            return {
              ...el,
              orgId:this.orderDetail.orgId,
              list : [],
              bdemandQty : 0,
              bQty: el.shippedBqty,
              borderedQty: el.waitStockBQty,
              amount: el.bprice * el.shippedBqty,
            }
          })
          this.$forceUpdate()

        })
        .finally(() => {
          this.pageLoadFlag = false;
        });

    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        return sum + Number(item.bdemandQty || 0);
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
        return sum + Number(item.bdemandQty || 0) * Number(item.volume || 0);

      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        return sum + Number(item.bdemandQty || 0) * Number(item.bprice || 0);

      }, 0);
    },
    getxj(bprice, borderedQty, item) {
      item.amount = Number((item.bprice || 0) * (item.bdemandQty || 0)).toFixed(2);
      return item.amount;
    },
    dataChange(e,index){
      this.index=index
      this.$forceUpdate()
      this.productList[this.index].list.push({})
    },
    reduce(e,index){
      this.index=index
      this.$forceUpdate()
      this.productList[this.index].list.pop()
    },
    inputChange(e,index,num){
      this.index=index
      this.oldNum= this.productList[this.index].list.length
      this.newNum= e
      let numBer = 0
      if(this.oldNum>this.newNum){
        numBer = this.oldNum-this.newNum
        this.productList[this.index].list.splice(0,numBer)
      }
      if((this.oldNum<this.newNum)){
        numBer = this.newNum - this.oldNum
        for (let i=0; i<numBer;i++){
          this.productList[this.index].list.push({})
        }
      }
    },
    // 产品修改新增操作
    openEdit(item,index,it,itIndex){
      // 打开修改弹窗
      this.editModal = true
       // 修改的数据
      this.editRowInfo = it
      // 修改的数据的下标
      this.editIndex = itIndex
      this.proIndex=index
    },
    // 新增产品确定获取数据
    getApplyList(e){
      const models = this.productList.map(it => (it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse));
      e.forEach(it=>{
        // 如果没有想同型号的的数据 就push一条
        if(models.includes(it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse)) {
          // 如果有的话  就向有的数据中新增一条
          this.productList.forEach(item => {
            if (item.modelName+'-'+item.materialCode +'-'+ item.gicWarehouse == it.modelName+'-'+it.materialCode +'-'+ it.gicWarehouse ) {
              item.list.push(...(it.list))
              item.count += 1
            }
          })
        }
        if(!models.includes(it.modelName+'-'+it.materialCode+'-'+ it.gicWarehouse)){
          this.productList.push((JSON.parse(JSON.stringify(it))))
        }
      })
    },

    getApplyListUpdate(list,count) {
      if(count!== 0 ) {
        this.productList[this.editIndex].list = list
        this.productList[this.editIndex].count = count
      } else {
        this.productList.splice(this.editIndex,1)
      }

    },
    updateRow(row){
      this.productList[this.proIndex].list[this.editIndex]= {
        ...this.productList[this.proIndex].list[this.editIndex],
        ...row
      }
      this.$set(this.productList[this.proIndex],'list',this.productList[this.proIndex].list)
      this.$forceUpdate()

    },
    // 切换有无鉴定单号
    changIdentificatioSheetNo(e){
      this.IdentificatioSheetNo = e.target.value;
      //  清空下面数据
      this.productList = this.productList.map(el => {
        return {
          ...el,
          list : [],
          bdemandQty : 0
        }
      })
    },

     // 更改地址
     handleChangeBussise(value, e) {
      this.shopId = e.key
      this.shopName = value
        // &&  this.shopName.indexOf("代理") !== -1
       if(this.isFenxiaoOrder.indexOf("17451") !== -1 ) {
         this.showTip = true;
       } else {
         this.showTip = false;
       }

    },
    //  获取下拉信息
    getinfor() {
      getInitInfor({}).then(res => {
        this.BussiseGroup = res.data.custList
        // this.shopName = res.data.custList[0].fullName
        // this.shopId = res.data.custList[0].mdmCode
        // 判断是否是分销商
        //   &&  this.shopName.indexOf("代理") !== -1
        if(this.isFenxiaoOrder.indexOf("17451") !== -1 ) {
          this.showTip = true;
        } else {
          this.showTip = false;
        }
        // this.shopName = res.data.defectiveProductModel.customerName
        this.phoneCon = res.data.defectiveProductModel.proposerTel
        this.contacts = res.data.defectiveProductModel.proposerName
        // 销售组织
        this.orderDis = res.data.orgList
        // 拉货方式
        this.deliveryOptions = res.data.edtList
        // this.trans = this.deliveryOptions[0].propertyValue;
        // this.transName = this.deliveryOptions[0].propertyName
      })
    },
    getOrg() {
      let data = {
        type: 2
      }
      queryOrg(data).then(res => {

        this.orgArr = res.data.orgList
      })
    },

    // 选择销售组织后进行物料组织的联动
    handleChangorgId(value, e) {
      this.receive = value;
      this.receiveID = e.key;
      this.receiveAdd = !this.receiveAdd;
      this.getMetarialGroup(e.key);

      this.orderDis.forEach(item => {
        if (item.organizationCode == this.receiveID) {
          this.orgzzId = String(item.id)
        }
      })
      //获取办事处
      getBscList(this.orgzzId).then((res) => {
        this.officeArr = res.data.list;
        this.officeId = res.data.list.length>0? res.data.list[0].code:''
      });
    },
    // 物料组织获取
    getMetarialGroup(id) {
      this.MaterialGroup = []
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        orgCode: id
      }
      getMatklByOrg(data).then(res => {
        if (res.data) {
          this.MaterialGroup = this.getNewObj(res.data)
          if (this.MaterialGroup.length > 0) {
            // v-model的物料组名字
            this.wlz = this.MaterialGroup[0].name
            // 物料组id
            this.wlzid = this.MaterialGroup[0].id
            this.getAdress()
          }
        } else {
          this.MaterialGroup = []
        }
      })
    },
    //  根据组织id和物料组id 获取 地址下拉数据
    getAdress() {
      this.adressGrou = []
      let data = {
        orgCode: this.receiveID,
        productModel: this.wlzid
      }
      getshAddress(data).then(res => {
        if (res.data) {
          this.adressGroup = this.getNewObj(res.data)
          if (this.adressGroup.length > 0) {
            this.adressName = this.adressGroup[0].name
            this.addressId = this.adressGroup[0].id
          }
        } else {
          this.adressGroup = []
          this.adressName = ''
          this.addressId = ''
        }
      })
    },
    // 数据重购
    getNewObj(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
        });
      }
      return newArr
    },
    // 更改物料组
    handleChangemarerial(value, e) {
      this.wlzid = e.key
      this.wlz = value
      this.getAdress()
    },
    // 更改地址
    handleChangeAddress(value, e) {
      this.addressId = e.key
      this.adressName = value
    },
    // 更改拉货方式
    onGiveType(e) {
      this.trans = this.deliveryOptions[e.target.value].propertyValue;
      this.transName = this.deliveryOptions[e.target.value].propertyName
    },
    // 打开编辑物料编码页面 编辑当前信息
    editRow(item,index,it,itIndex) {
      // 修改的数据
      this.editRowInfo = it
      // 修改的数据的下标
      this.editIndex = itIndex
      this.proIndex=index
      if(!it.materialCode){
        this.showModalIdent=true
        this.productName = item.model
        this.materialCode = item.materialCode
        this.gicOutWarehouseName = item.gicOutWarehouseName
        this.gicOutWarehouse = item.gicOutWarehouse
        this.orgCodeF = item.orgId
        this.invStatusTypeName = item.invStatusTypeName
        this.invStatusType = item.invStatusType
        this.invStatusId = item.invStatusId
        this.invStatusName = item.invStatusName
      } else {
        this.editModal=true
      }
    },
    // 删除物料编码信息
    delect(item,index,it,itIndex){
      this.productList[index].list.splice(itIndex, 1);
      this.productList[index].bdemandQty =  this.productList[index].list.length
     },
    //删除商品
    delProduct(index) {
      this.productList.splice(index, 1);
    },
    // 新的电点击确定
    confirmOk(row){
      this.productList[this.proIndex].list[this.editIndex]= {
        ...this.productList[this.proIndex].list[this.editIndex],
        ...row
      }
      this.$set(this.productList[this.proIndex],'list',this.productList[this.proIndex].list)
      this.$forceUpdate()
    },
    addProduct(productList) {

      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000067'
          })
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        const models = this.productList.map(it => it.productCode);
        productList.forEach(it => {
          if (!models.includes(it.productCode)) {
            it.checked = true;
            it.itemId = "";
            it.bprice = it.retailPrice
            it.message = ''
            it.MachineCode = ''
            it.ProNum = Number(0)
            models.push(it.productCode);
            this.productList.push(it);
          } else {
            this.productList.forEach(item => {
              if (item.productCode == it.productCode) {
                item.ProNum = Number(item.ProNum)
                item.ProNum += 1
              }
            })
          }
        });
      } else {
        if (productList.length > 0) {
          let pro = productList[0];
          const index = this.productList.findIndex(
            it => it.productCode == this.selRow.productCode
          );
          pro = {
            ...pro,
            checked: true,
            itemId: "",
            bprice: this.productList[index].bprice
          };
          this.$set(this.productList, index, pro);
        }
      }


    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 获取当前时间
    fun_date() {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return yy + "-" + mm+ "-" + dd +' '+ hh+ ":"+ mf+ ":"+ ss;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    // 订单提交
    submitOrder() {
      if (!this.receiveID) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.adressName) {
        this.$message.warning('收货地址不能为空');
        return;
      }
      if (!this.shopName) {
        this.$message.warning('请选择开票户头');
        return;
      }
      if(!this.trans){
        this.$message.warning('请选择拉货方式');
        return;
      }
      if(this.productList.length<=0){
        this.$message.warning('请选择商品');
        return;
      }
      for (let i = 0;i<this.productList.length;i++){
        // if (this.productList[i].bdemandQty<=0){
        //   this.$message.warning('请填写产品信息')
        //   return
        // }
        if(this.productList[i].list.length>0){
          for (let j = 0; j < this.productList[i].list.length; j++) {
            if(!this.productList[i].list[j].materialCode){
              this.$message.warning('请填写产品信息')
              return
            }
          }
        }
      }
      let sumbitListOld = []
      this.productList.forEach( item => {
        if(item.list.length>0){
          sumbitListOld.push(...(item.list))
        }
      })
      if(sumbitListOld.length<= 0){
        this.$message.warning('请填写产品信息')
        return
      }
      let  sumbitListNew = []
      sumbitListOld.forEach(proItem => {
        // 给后端传值 如果有鉴定订单号机编码前十二位和选择的产品的物料号不一致  截取12位传给后端
        proItem.matnrFromVerifyCode = ''
        if(proItem.identificationSheeNo &&(proItem.oldMaterialCode&&proItem.machineCode)&& (proItem.oldMaterialCode!== proItem.machineCode.slice(0,12))){
          proItem.matnrFromVerifyCode= proItem.machineCode.slice(0,12)
        }
        sumbitListNew.push({
          orgCode: proItem.orgCode,
          materialGroupCode: proItem.materialGroupCode,
          productModel: proItem.modelName,
          machineCode: proItem.machineCode,
          crmIdentificationCode: proItem.identificationSheeNo&&this.IdentificatioSheetNo==0?proItem.identificationSheeNo:'',
          identificationDate: proItem.identificationShee&&this.IdentificatioSheetNo==0?proItem.identificationShee:'',
          remark: proItem.remark,
          returnMachineType: proItem.reverseTypeId,
          gicWarehouseName:proItem.gicWarehouseName?proItem.gicWarehouseName:'',
          gicWarehouse:proItem.gicWarehouse?proItem.gicWarehouse:'',
          gicOwner:this.$store.state.user.userInfo.customer.customerCode,
          gicPriceprottype:proItem.invStatusTypeName?proItem.invStatusTypeName:'',
          matnr:proItem.oldMaterialCode?proItem.oldMaterialCode:proItem.materialCode,
          "matnrFromVerifyCode":proItem.matnrFromVerifyCode?proItem.matnrFromVerifyCode:'',
          "specialFlag":proItem.duplicateRecord?proItem.duplicateRecord:'',
          "duplicateCause":proItem.repeatedReasons?proItem.repeatedReasons:'',
          gicInInvDate:this.fun_date(),// 获取当前时间
      })
      })
      let arr = []
      this.productList.forEach(it=>{
          if (it.list && it.list.length>0) {
            arr.push({
              model: it.model,
              productCode: it.productCode,
              borderedQty: it.bdemandQty,
              bprice: it.bprice,
              amount: it.bprice * it.bdemandQty,
              invStatusId: it.invStatusId,
              invStatusType: it.invStatusName,
              warehouseId: it.gicOutWarehouse ? it.gicOutWarehouse : '',
              imperfectQty: 0,
            })
          }
      })
      if(arr.length<= 0){
          this.$message.warning('请填写产品信息')
          return
      }
      let sumbitObj = {
        "organizationId": this.orgzzId ,//销售组织id
        "organizationCode": this.receiveID,//销售组织编码
        "invoiceMdmCode": this.shopId, // 开票方
        "officeId":this.officeId,// 办事处
        // "customerName": this.shopName, // 开票方名称
        "materialGroupCode": this.wlzid,//物料组code
        "returnAddressId": this.addressId, // 退货地址id
        "contactPerson": this.contacts, // 联系人
        "contactPhone": this.phoneCon, // 联系名称
        "pickUpTypeId": this.trans, // 拉货方式
        identificationFlag: this.IdentificatioSheetNo==1?0:1, // 有无鉴定单号 1有 0无
        productInfoList:sumbitListNew, // 产品信息
      }
      this.isLoading  = true
      getCustomerMarketModel(
        {
          custMdmCode: this.$store.state.user.userInfo.customer.customerCode,
          matklCode: this.wlzid,
          orgId: this.orgzzId,
        }
      ).then(res => {
        const { data } = res.data;
        if(!data){
          this.isLoading  = false
          this.$message.warning("商家该物料组的合作关系已冻结，不可发起残次退。")
        }else{
          this.submitClick(arr, sumbitObj)
        }
      }).catch(error => {
        this.isLoading  = false
      })
    },
    submitClick(arr, sumbitObj){
      createReturnOrderInbound({
        returnOrder:{
          documentNum: this.orderNum,
          message:'',
          amount:'',
          returnItemList:arr,
          isImperfect:true,
        }
      }).then(res=>{
        let realData = JSON.parse(res.data);
        if (realData.code == '0') {
          submit(sumbitObj).then(response => {
            if(response.data.code == 0) {
              // dms保存残次退单号，用于渠道/销售订单详情展示
              addImperfectReturnOrder({
                orderId:  this.$route.query.id, // 业务单ID
                orderNum: this.orderNum, // 业务单号
                returnOrderId: response.data.id || '', // 退残单ID
                returnOrderNum:  response.data.data || '', // 退残单号
              })
              this.isLoading  = false
              this.$router.push({
                path: "/hisenseReturn/success",
                query: {
                  b2bOrderCode: response.data.data,
                  type: "HXTH",
                  page: "new"
                }
              });

            } else if(res.data.code !== 400) {
              this.$message.warning(response.data.msg)
              this.isLoading  = false
            } else {
              this.isLoading  = false
            }

          }).catch(error => {
            this.isLoading  = false
          })
        } else {
          this.$message.warning(realData.msg);
          this.isLoading  = false
        }
      }).catch(error => {
        this.isLoading  = false
      })
    },
    goCar() {
      this.$router.push("/hisenseReturn/defectiveReturnListNew");
    },

    // 新增产品弹框
    openAdd() {
      if (!this.receiveID) {
        // this.$message.warning("请选择销售组织");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000070'
        })
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      this.showModalIdent = true
    },
  }
};
