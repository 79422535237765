/*
 * @Author: xiaojie 1264801634@qq.com
 * @Date: 2022-08-24 09:28:25
 * @LastEditors: xiaojie 1264801634@qq.com
 * @LastEditTime: 2022-08-30 08:34:33
 * @FilePath: \B2B\src\views\distributorWarehouse\addDistributorWarehouse\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import http from "@/utils/request";
import {getStoreUser1} from "@/utils/store"



// ********************************************************
//获取仓库详情
export function warehouseDetail (feed) {
    return http({
        method: "get",
        url: `/custShopInfoCk/detail.nd?id=${feed}`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}

/**
 * 新增建仓和编辑接口
 * @param {*} data 
 * @returns 
 */
 export function distributorWarehouse(data) {
    return http({
        method: "post",
        url: "/custShopInfoCk/flowStart.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
}

//一步建仓接口
export function stepStartFlow(data) {

    var type = getStoreUser1()
    console.log(type);
    return http({
        method: "post",
        url: "/stepBuildStore/stepStartFlow/"+type+".nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data: data
    });
}

/**
 * 新物料组下拉框
 * @param {*} data 
 * @returns 
 */
 export function getMatklListNew() {
    return http({
        method: "get",
        url: `/distributorCreation/findMaterialGroupCombobox.nd`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

/**
 * 物料组下拉框
 * @param {*} data 
 * @returns 
 */
export function getMatklList(data) {
    return http({
        method: "get",
        url: `/fxshop/getMatklList.nd?customerInfoId=${data}`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}


/**
 * 办事处下拉框
 * @param {*} data 
 * @returns 
 */
 export function getBscList(data) {
    return http({
        method: "get",
        url: `/fxshop/getBscList.nd?fgsId=${data}`,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}