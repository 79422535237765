<template>
  <a-modal
    v-model="show"
    title
    class="product-model"
    @cancle="cancle"
    :footer="null"
  >
    <div class="close-icon" slot="closeIcon" title="关闭">
      <i class="iconfont icon-danchuangguanbi-anniu"></i>
    </div>
    <div class="heade-top">
      <p class="top">
        <span class="star">*</span>
        <span style="margin-right: 8px">请输入型号：</span>

        <a-auto-complete
          v-model="model"
          :data-source="modelList"
          class="auto-input"
          placeholder=""
          @search="onSearch"
          @select="search"
          disabled
        >
          <template slot="dataSource">
            <a-select-option v-for="item in modelList" :key="item">
              {{ item }}
            </a-select-option>
          </template>
          <a-input
            v-model="model"
            style="width:360px"
            placeholder=""
            @keydown.enter="search"
          >
            <i
              class="iconfont icon-shouye-sousuo"
              slot="suffix"
              @click="search"
            />
          </a-input>
        </a-auto-complete>
        <h-btn @click="search" class="auto-btn" :width="88" :height="32"
        >查询
        </h-btn>
      </p>
      <p style="margin-left:389px;color:#FF8F3C;font-size:14px">
        <span style="color:#FF8F3C;font-size: 12px">提示：此处只查询 T 批次库存</span>
      </p>
      <p class="count-span" v-show="filterData.length > 0">
        共搜索到 {{ pager.count || 0 }} 条数据
      </p>
    </div>
    <div class="dataNull" v-show="filterData.length <= 0">
      <div>
        <img src="../../../../assets/order/noList.png" alt="" />
        <p>暂无数据，请试试其他筛选条件~</p>
      </div>
    </div>
    <div v-show="filterData.length > 0">
      <hi-table
        :showLoading="tabelIsLoading"
        v-if="show && tabShow"
        ref="tb"
        :max-height="maxHeight"
        :data="filterData"
        :header="columns"
        border
        :autoWidth="false"
        fit
        highlight-current-row
        @row-dblclick="addRow"
      >
        <template slot="btnGroup" slot-scope="scope">
          <a class="btn-a" @click="addRow(scope.row)">添加</a>
        </template>
      </hi-table>
      <div class="page-check-wrap clearfix">
        <div class="check-wrap">
          <!--        <a-checkbox @change="onlyShowCheckedChange" :checked="onlyShowChecked">-->
          <!--          <span style="padding-left:8px">仅查看已选</span>-->
          <!--        </a-checkbox>-->
        </div>
        <div class="footer-pager">
          <Pagination :pager="pager" @query="getData('pageChange')" />
        </div>
      </div>
    </div>
    <div class="footer-btn-wrap">
      <h-btn
        class="h-btn"
        :width="188"
        :height="48"
        background="#AAAAAA"
        color="#FFFFFF"
        type="default"
        content="返回"
        @click="cancle"
        borderRadius="4"
      >返回
      </h-btn>
    </div>
    <div style="clear:both;width:100%;"></div>
  </a-modal>
</template>

<script>
import {findProductListLikeCode, getRetailPrice,getProductListNew } from "./api";

export default {
  name: "ProductModal",
  props: {
    modelShow: {
      type: Boolean,
      required: true
    },
    orgId: {
      type: String,
      default: function() {
        return "";
      }
    },
    materialGroupCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    warehouseId: {
      type: String,
      default: function() {
        return "";
      }
    },
    rowSelectionType: {
      type: String,
      default: "checkbox" //radio
    },
    type: {
      type: String,
      required: true
    },
    productName: {
      type: String,
      required: true
    }
  },
  model: {
    prop: "modelShow",
    event: "input"
  },

  data() {
    return {
      maxHeight: 500,
      show: false,
      tabShow: true,
      model: "",
      modelList: [],
      columns: [],
      dataCache: [],
      data: [],
      selRows: [],
      onlyShowChecked: false,
      pager: {
        count: "",
        pageNo: 1,
        pageSize: 10
      },
      tabelIsLoading: false
    };
  },
  beforeCreate() {
    try {
      this.maxHeight = window.innerHeigh - 350;
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    //表格数据/切换只显示勾选使用
    filterData() {
      if (this.onlyShowChecked) {
        const productCodes = this.selRows.map(it => it.uid);
        const resu = this.data.filter(it => {
          return productCodes.includes(it.uid);
        });
        return resu;
      } else {
        return this.data;
      }
    }
  },
  watch: {
    modelShow: {
      handler: function(val) {
        this.show = this.modelShow;
        if (val) {
          this.pager = {
            count: 0,
            pageNo: 1,
            pageSize: 10
          };
          if (!this.model || this.model.length < 2) {
            this.selRows = []; //切换页码清空勾选的行
            this.onlyShowChecked = false;
            this.data = [];
            return;
          }
          this.getData();
        }
      },
      immediate: true
    },
    type: {
      handler: function(val) {
        if (this.type == "inApply") {
          this.columns = [
            {
              label: "型号",
              prop: "model",
              key: "model"
            },
            {
              label: "颜色",
              prop: "colour",
              key: "colour"
            },
            {
              label: "物料组",
              prop: "materialGroupName",
              key: "materialGroupName"
            },
            {
              label: "体积(m³)",
              prop: "volume",
              key: "volume"
            },
            {
              label: "操作",
              prop: "",
              key: "borderedQty",
              slot: "btnGroup"
            }
          ];
        } else {
          this.columns = [
            {
              label: "型号",
              prop: "model",
              ellipsis: true,
              key: "model"
            },
            {
              label: "颜色",
              prop: "colour",
              width: "80",
              key: "colour"
            },
            {
              label: "物料组",
              prop: "materialGroupName",
              width: "100",
              ellipsis: true,
              key: "materialGroupName"
            },
            {
              label: "物料编码",
              prop: "materialCode",
              width: "100",
              ellipsis: true,
              key: "materialCode"
            },
            {
              label: "价格(元)",
              prop: "retailPrice",
              width: "100",
              key: "retailPrice"
            },
            {
              label: "体积(m³)",
              prop: "volume",
              width: "110",
              key: "volume"
            },
            {
              label: "仓库",
              prop: "warehouseName",
              ellipsis: true,
              key: "warehouseName"
            },
            {
              label: "质量等级",
              prop: "invStatusName",
              width: "110",
              key: "invStatusName"
            },
            {
              label: "补差类型",
              prop: "invStatusTypeName",
              width: "110",
              key: "invStatusTypeName"
            },
            {
              label: "可用库存",
              prop: "bigQty",
              width: "110",
              key: "bigQty"
            },
            {
              label: "操作",
              prop: "",
              key: "borderedQty",
              width: 80,
              slot: "btnGroup"
            }
          ];
        }
      },
      immediate: true
    },
    show: {
      handler: function(val) {
        this.$emit("input", val);
      }
    },
    productName:{
      handler: function(old,val) {
        this.model = this.productName
      }
    }
  },
  methods: {
    addRow(row) {
      const selRows = JSON.parse(JSON.stringify([row]));
      this.$emit("submit", selRows);
      this.$message.info("添加成功，请关闭弹窗或继续添加!");
      // this.$emit("input", false);
    },
    //获取型号数据
    onSearch() {
      const data = {
        term: this.model
      };
      findProductListLikeCode(data).then(res => {
        if (res.data) {
          res.data.forEach(item => {
            data.push({
              model: item
            });
          });
        }

        this.modelList = [...new Set(data.map(it => it.model))]; //缓存列表数据，前端分页
      });
    },
    getNewObj(obj) {
      let newArr = [];
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key]
        });
      }
      return newArr;
    },
    //行选中，取消
    onChange(selKeys, selRows) {
      this.selRows = selRows;
    },
    //只显示勾选
    onlyShowCheckedChange(e) {
      this.onlyShowChecked = e.target.checked;
    },
    //确认按钮
    submit() {
      if (this.selRows.length == 0) {
        this.$message.warning("请选择产品!");
        return;
      }
      const selRows = JSON.parse(JSON.stringify(this.selRows));
      this.$emit("submit", selRows);
      this.$emit("input", false);
    },
    //取消或者关闭
    cancle() {
      this.$emit("input", false);
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    //搜索按钮事件
    search() {
      if (!this.model || this.model.length < 2) {
        this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    //调接口获取数据
    getData(type) {
      this.tabelIsLoading = true;
      if (this.type == "inApply") {
        const reauestData = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          warehouseId: "",
          // warehouseId: this.warehouseId,
          productCode: this.model,
          // orgId: this.orgId,
          page: {
            pageNo: this.pager.pageNo,
            pageSize: this.pager.pageSize
          }
        };
        findProductListLikeCode(reauestData).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          data.products.forEach(it => {
            it.volume = it.volume ? Number(it.volume).toFixed(3):0;
            it.borderedQty = 1;
            it.invStatus = it.invType;
            it.bigUom = it.uom;
            it.uid = this.genID(10);
          });
          this.selRows = []; //切换页码清空勾选的行
          this.onlyShowChecked = false;
          this.pager.count = data.page.totalCount;
          this.dataCache = data.products; //缓存列表数据，前端分页
          this.data = data.products;
        });
      } else {
        const requestDataNew = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          terms: {
            model: this.model,
            colour: "",
            warehouseId: this.warehouseId,
            invStatusId: "",
            invStatusName: "T",
            isLock: "",
            dealerMaterialGroupFlag: "",
            materialGroupCode: this.materialGroupCode,
            materialGroupName: "",
            gicWarehouseType: "70",
            invStatusType: "",
            invType: "110",
            bigQtyMin: 0,
            orgId: this.orgId,
            gicInvStatus: "110",
            isFuzzy: false
          },
          page: {
            pageNo: this.pager.pageNo,
            pageSize: this.pager.pageSize
          }
        };
        getProductListNew(requestDataNew).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          const start = (this.pager.pageNo - 1) * this.pager.pageSize;
          data.data.forEach(it => {
            if (this.type == "sale" || this.type == "channel") {
              it.borderedQty = 1;
              it.invStatus = it.invStatusId;
              it.bigUom = it.uom;
            } else {
              it.borderedQty = 1;
              it.invStatus = it.invType;
              it.bigUom = it.uom;
            }
            it.uid = this.genID(10);
          });

          if (this.type == "sale") {
            for (const i in data.data) {
              getRetailPrice({ productId: data.data[i].productCode }).then(
                priceRes => {
                  this.data[i].retailPrice = priceRes.data.price;
                  this.tabShow = false;
                  this.$nextTick(() => {
                    this.tabShow = true;
                  });
                }
              );
            }
          }

          this.selRows = []; //切换页码清空勾选的行
          this.onlyShowChecked = false;
          this.pager.count = data.page.totalCount;
          this.dataCache = data.data; //缓存列表数据，前端分页
          this.data = data.data.slice(start, start + this.pager.pageSize);
          this.tabelIsLoading = false;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.product-model {
  /deep/ .ant-modal-content {
    border-radius: 10px;
  }

  /deep/ .ant-modal-close-x {
    width: 42px;
    height: 42px;
    border-top-right-radius: 10px;
    line-height: 42px;

    .close-icon {
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 42px;

      i {
        //color: #00aaa6;
        font-size: 16px;
      }
    }

    &:hover {
      background: #e4f8f7;

      i {
        color: #00aaa6;
      }
    }
  }

  .heade-top {
    margin-bottom: 12px;

    .auto-input {
      width: 360px;
    }

    .star {
      color: rgba(217, 22, 28, 1);
    }

    .auto-btn {
      margin-left: 16px;
    }

    .top {
      text-align: center;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
      line-height: 20px;
    }

    ///deep/ .ant-input-affix-wrapper .ant-input-suffix {
    //  right: -2px;
    //}
    //
    ///deep/ .ant-input {
    //  width: 270px;
    //  height: 32px;
    //  display: inline-block;
    //  margin-left: 12px;
    //}
    //
    ///deep/ .ant-input:hover {
    //  box-shadow: 0px 0px 3px 2px rgba(152, 251, 248, 0.34);
    //  border-radius: 4px;
    //  border: 1px solid #00aaa6;
    //}
    //
    ///deep/ .ant-input-affix-wrapper {
    //  width: 270px;
    //  height: 32px;
    //}
  }

  /deep/ .ant-modal {
    width: 1188px !important;
    margin: 0 auto !important;

    .ant-modal-title {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #262626 !important;
      line-height: 20px !important;
    }

    .ant-modal-body .ant-table-column-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .count-span {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    text-align: left;
    margin-top: 17px;
  }

  .page-check-wrap {
    padding-top: 8px;
    padding-bottom: 6px;

    .check-wrap {
      float: left;
      padding-top: 12px;
    }

    .footer-pager {
      float: right;
    }
  }

  .footer-btn-wrap {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .h-btn {
      margin: 0 15px;
    }
  }

  /deep/ .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
}

.dataNull {
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
  height: 340px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 120.24px;
    margin-bottom: 16px;
    margin-left: 23px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
  }
}
.btn-a {
  color: #1890ff;
}
.icon-shouye-sousuo:hover {
  color: #00aaa6;
}
</style>
