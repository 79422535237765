<template>
  <div
    class="number-box"
    :class="{ 'box-disable': isDisabled, 'number-box-small': size == 'small' }"
  >
    <span class="btn-number subtract" :class="!subtractFlag ? 'disbled' : ''" @click="onReduce">
      <i class="iconfont icon-shuliangjian"></i>
    </span>
    <!-- <input
        class="number-container"
        v-model="strVal"
        :disabled="isDisabled"
    />-->
    <input class="number-container" disbled @change="inputChange" v-model="numVal"/>
    <span class="btn-number add" :class="!addFlag ? 'disbled' : ''" @click="onIncrease">
      <i class="iconfont icon-shuliangjia"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "number-box",
  props: {
    isDisabled: {
      //是否禁用
      type: Boolean,
      default: false
    },
    curVal: {
      type: [String, Number],
      default: "0"
    },
    minVal: {
      type: [String, Number],
      default: "0"
    },
    maxVal: {
      type: [String, Number],
      default: "999"
    },
    size: {
      type: [String, Number],
      default: "middle" //middle | small
    },
  },
  data() {
    return {
      numVal: 0,
      subtractFlag:
        this.curVal == this.minVal || this.isDisabled ? false : true,
      addFlag: this.curVal == this.maxVal || this.isDisabled ? false : true,
      oldNumber:0,
    };
  },
  watch: {
    //监听输入或点击数值变化
    numVal: {
      handler(value, oldValue) {
        if (value == "" || value == null) {
          // this.numVal = "";
          this.subtractFlag = false;
          return;
        } else {
          //只可输入数字
          this.numVal = Number(value.toString().replace(/[^\d]/g, ""));
        }
        if (Number(value) <= this.minVal && this.minVal != null) {
          // this.numVal = Number(this.minVal);
          this.subtractFlag = false;
        } else {
          this.subtractFlag = true;
        }
        if (Number(value) >= this.maxVal && this.maxVal != null) {
          this.numVal = Number(this.maxVal);
          this.addFlag = false;
        } else {
          this.addFlag = true;
        }

      }
    },
    maxVal: {
      handler(value, oldValue) {
        if (Number(value) >= this.maxVal && this.maxVal != null) {
          this.addFlag = true;
        } else {
          this.addFlag = false;
        }

      }
    },
    curVal: {
      immediate: true,
      handler(value,oldValue) {
        this.numVal = Number(value);
        if (Number(this.numVal) <= this.minVal && this.minVal != null) {
          this.numVal = this.minVal;
          this.$emit("update:curVal", this.minVal);
          this.subtractFlag = false;
        } else {
          this.subtractFlag = true;
        }

        if (Number(this.numVal) >= this.maxVal && this.maxVal != null) {
          this.numVal = this.maxVal;
          this.$emit("update:curVal", this.maxVal);
          this.addFlag = false;
        } else {
          this.addFlag = true;
        }
        this.$forceUpdate()
      }
    },


  },
  methods: {
    onReduce() {
      this.numVal -= 1;
      this.$emit("update:curVal", this.numVal);
      this.$emit("onReduceNumber", this.numVal);
    },
    onIncrease() {
      this.numVal += 1;
      this.$emit("update:curVal", this.numVal);
      this.$emit("getNumber", this.numVal);
    },
    inputChange() {
      //置为最小值
      if(Number(this.numVal) <= this.minVal){
        this.numVal = Number(this.minVal);
      }
      this.$emit("update:curVal", parseInt(this.numVal) || 0);
      this.$emit("inputNumChange",parseInt(this.numVal) || 0 );
    }
  }
};
</script>

<style lang="less" scoped>
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  &.box-disable {
    pointer-events: none;
    // background: #999;
    .number-container {
      color: #aaa;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
    .btn-number {
      background: #eeeeee;
      i {
        color: #aaa;
      }
    }
  }
  .btn-number {
    width: 40px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background-color: #ecf8f8;
    border-radius: 19px 0px 0px 19px;
    // border: 1px solid #EEEEEE;
    cursor: pointer;
    color: #00aaa6;
    i {
      font-size: 12px;
    }
    &.add {
      // line-height: 36px;
      border-radius: 0px 19px 19px 0px;
    }
    &.subtract {
      // font-size: 26px;
      // line-height: 30px;
    }
    &.disbled {
      pointer-events: none;
      color: #262626;
      background-color: #eee;
    }
  }
  .number-container {
    max-width: 70px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    outline: none;
    border-top: 1px solid #ecf8f8;
    border-bottom: 1px solid #ecf8f8;
  }
}
.number-box-small {
  .btn-number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    &.add {
      line-height: 25px;
      // border-radius: 0px 2px 2px 0px;
      font-size: 13px;
    }
    &.subtract {
      font-size: 13px;
      line-height: 25px;
    }
  }
  .number-container {
    width: 30px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
  }
}
</style>
